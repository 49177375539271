span.chord-box{
    font-size: 1em;
    position: absolute;
    font-weight: 800;
    transform: translateY(-1em);
    cursor: pointer;

    .chord{
        margin-right: .5em;
        position:relative
    }

    .chord-chart{
        width: 5em;
        height: 5em;
        position: absolute;
        top:-5em; left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary-bg);
        box-shadow: var(--shadow);
        border-radius: var(--border-radius);
        z-index: 200;
        font-weight: 400;
        svg{
            transform: scale(1.1);
        }
        .no-chord{
            font-size: 1.5em;
        }
    }
}

.dark-theme span.chord .chord-chart svg{
    filter: invert(1);
}