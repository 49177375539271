#settings {
    padding: 2em;
    @media (max-width: 600px) {
        padding: 1em;
    }
    max-width: 1200px;
    margin: auto;
    min-height: calc(100% - 4em);
    display: flex;
    flex-direction: column;

    .grid {
        display: grid;
        grid-template-columns: auto 1fr;
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        grid-gap: 2em;
        flex-grow: 1;
        h2 {
            margin-top: 0;
        }
        .left .item {
            margin-top: 1em;
            padding: 0.6em 1.2em;
            cursor: pointer;
            font-size: 0.8em;
            font-weight: bold;
            background-color: var(--menu-bg);
            border-radius: var(--border-radius);
            //box-shadow: var(--shadow);
            svg {
                margin-right: 1em;
            }
            &:hover {
                background-color: var(--menu-hover-bg);
            }
            display: flex;
            align-items: center;
        }
    }

    .back {
        margin: 0em 0em 0.5em;
        font-size: 2em;
    }

    h1 {
        margin: 0em 0em 0.5em;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            cursor: pointer;
        }
    }

    h2 {
        margin: 1em 0em 0.5em;
        font-weight: 300;
    }

    hr {
        border: none;
        border-bottom: 1px solid #777;
        margin: 1em 0em;
    }

    .info {
        font-size: 0.8em;
        color: #777;
    }

    .session {
        .buttons {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1em;
            margin-top: 0.85em;
            padding-bottom: 1em;
            .button {
                padding: 0.5em 1em;
                box-shadow: var(--shadow);
                border-radius: var(--border-radius);
                background-color: var(--widget-bg);
                &:hover {
                    background-color: var(--widget-bg-hover);
                }
            }
        }
    }

    .collection {
        padding: 0.5em 0em;
        margin: 0.5em 0em;
        display: flex;
        border-radius: var(--border-radius);
        &:hover {
            background-color: var(--menu-hover-bg);
        }
        .title {
            font-weight: 700;
        }
        .id {
            color: #777;
            font-size: 0.8em;
        }
        .left {
            padding: 0em 1em;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .middle {
            flex-grow: 1;
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0em 1rem;
            cursor: pointer;
            :hover {
                background-color: var(--menu-hover-bg);
            }
            &.delete {
                font-size: 2em;
            }
            &.download {
                font-size: 1.5em;
            }
        }
    }
}
