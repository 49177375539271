#song{
    position: relative;
    padding: 2em;
    @media(max-width:600px){padding: 1em}
    display: flex;
    flex-direction: column;
    h1.title{
        text-align: center;
        margin-top: 0em;
        margin-bottom: .25em;
        font-size: 1.5em;
    }
    h2.author{
        text-align: center;
        margin: 0em;
        font-size: 1.1em;
        font-style: italic;
        font-weight: 600;
    }

    align-items: center;

    .lyrics{
        column-gap: 2em;
        margin-top:1em;
        
    .capo{
        //text-align: right;
    }

    .verse{
            &:not(:first-child){padding-top: 1em;}
            break-inside: avoid;
            position: relative;
            padding-left: 1.5em;
            min-height: 2em;
            .before{
                position:absolute;
                left: -1.5em;
                font-weight: 800;
            }
        }
    }


}