#lyrics {

    scroll-margin-top: 1em;
    .chord-lyrics {
        .lyrics-line {
            position: relative;
            margin: 0em;
            font-size: 1em;
            z-index: 100;
            &:first-child.with-chords {
                padding-top: 1em;
            }
            &.has-chords {
                line-height: 2.5em;
                &:first-child {
                    line-height: 2em;
                }
            }
            .hskip {
                display: inline-block;
            }
            .italic {
                font-style: italic;
            }
            .bold {
                font-weight: 800;
            }
        }
        .verse-name {
            position: absolute;
            transform: translateX(calc(-100% - .3em));
            font-weight: bold;
        }
    }

    &.no-line-break .lyrics-line>span{
        white-space: nowrap;
    }
}
