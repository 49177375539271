.number-input-component {
  display: inline-flex;
  align-items: center;
  input {
    background-color: var(--secondary-bg);
    border: none;
    width: 1.5em;
    text-align: center;
    height: 1em;
    padding: 0.4em 0.5em;
    outline: none;
    color: var(--primary-font-color);
    font-size: 0.9em;
    border-radius: var(--border-radius);
    margin: 0em 0.5em;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: var(--widget-bg);
    font-weight: 900;
    cursor: pointer;
  }
}
